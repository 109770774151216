body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* Custom CSS */

.square {
    position: absolute;
    top: 0;
    left: 0;
    width: 54%;
    height: 100%;
    z-index: 1;
}

.inner {
    width: 100%;
    height: 100%;
    border-left: 9px solid #770EFE;
    border-bottom: 9px solid #770EFE;
    border-top: 9px solid #770EFE;
    position: relative;
}

#hero-2 .hero-txt {
    padding: 120px 0 120px 60px;
}